"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var ClickOutsideDirective = /** @class */ (function () {
    function ClickOutsideDirective(_el, platformId) {
        this._el = _el;
        this.platformId = platformId;
        this.attachOutsideOnClick = false;
        this.delayClickOutsideInit = false;
        this.exclude = '';
        this.excludeBeforeClick = false;
        this.clickOutsideEvents = '';
        this.clickOutside = new core_1.EventEmitter();
        this._nodesExcluded = [];
        this._events = ['click'];
        this._initOnClickBody = this._initOnClickBody.bind(this);
        this._onClickBody = this._onClickBody.bind(this);
    }
    ClickOutsideDirective.prototype.ngOnInit = function () {
        if (common_1.isPlatformBrowser(this.platformId)) {
            this._init();
        }
    };
    ClickOutsideDirective.prototype.ngOnDestroy = function () {
        var _this = this;
        if (common_1.isPlatformBrowser(this.platformId)) {
            if (this.attachOutsideOnClick) {
                this._events.forEach(function (e) { return _this._el.nativeElement.removeEventListener(e, _this._initOnClickBody); });
            }
            this._events.forEach(function (e) { return document.body.removeEventListener(e, _this._onClickBody); });
        }
    };
    ClickOutsideDirective.prototype.ngOnChanges = function (changes) {
        if (common_1.isPlatformBrowser(this.platformId)) {
            if (changes['attachOutsideOnClick'] || changes['exclude']) {
                this._init();
            }
        }
    };
    ClickOutsideDirective.prototype._init = function () {
        var _this = this;
        if (this.clickOutsideEvents !== '') {
            this._events = this.clickOutsideEvents.split(' ');
        }
        this._excludeCheck();
        if (this.attachOutsideOnClick) {
            this._events.forEach(function (e) { return _this._el.nativeElement.addEventListener(e, _this._initOnClickBody); });
        }
        else {
            this._initOnClickBody();
        }
    };
    ClickOutsideDirective.prototype._initOnClickBody = function () {
        if (this.delayClickOutsideInit) {
            setTimeout(this._initClickListeners.bind(this));
        }
        else {
            this._initClickListeners();
        }
    };
    ClickOutsideDirective.prototype._initClickListeners = function () {
        var _this = this;
        this._events.forEach(function (e) { return document.body.addEventListener(e, _this._onClickBody); });
    };
    ClickOutsideDirective.prototype._excludeCheck = function () {
        if (this.exclude) {
            try {
                var nodes = Array.from(document.querySelectorAll(this.exclude));
                if (nodes) {
                    this._nodesExcluded = nodes;
                }
            }
            catch (err) {
                console.error('[ng-click-outside] Check your exclude selector syntax.', err);
            }
        }
    };
    ClickOutsideDirective.prototype._onClickBody = function (ev) {
        var _this = this;
        if (this.excludeBeforeClick) {
            this._excludeCheck();
        }
        if (!this._el.nativeElement.contains(ev.target) && !this._shouldExclude(ev.target)) {
            this.clickOutside.emit(ev);
            if (this.attachOutsideOnClick) {
                this._events.forEach(function (e) { return document.body.removeEventListener(e, _this._onClickBody); });
            }
        }
    };
    ClickOutsideDirective.prototype._shouldExclude = function (target) {
        for (var _i = 0, _a = this._nodesExcluded; _i < _a.length; _i++) {
            var excludedNode = _a[_i];
            if (excludedNode.contains(target)) {
                return true;
            }
        }
        return false;
    };
    ClickOutsideDirective.decorators = [
        { type: core_1.Injectable },
        { type: core_1.Directive, args: [{ selector: '[clickOutside]' },] },
    ];
    /** @nocollapse */
    ClickOutsideDirective.ctorParameters = function () { return [
        { type: core_1.ElementRef, },
        { type: Object, decorators: [{ type: core_1.Inject, args: [core_1.PLATFORM_ID,] },] },
    ]; };
    ClickOutsideDirective.propDecorators = {
        'attachOutsideOnClick': [{ type: core_1.Input },],
        'delayClickOutsideInit': [{ type: core_1.Input },],
        'exclude': [{ type: core_1.Input },],
        'excludeBeforeClick': [{ type: core_1.Input },],
        'clickOutsideEvents': [{ type: core_1.Input },],
        'clickOutside': [{ type: core_1.Output },],
    };
    return ClickOutsideDirective;
}());
exports.ClickOutsideDirective = ClickOutsideDirective;
